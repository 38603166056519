import React, { useState, useEffect } from 'react';
import { Button, Form, Container, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Logo from "./images/new_logo.png";

const Login = () => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        username: '',
        password: '',
    })
    const { username, password } = state;
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    

    const handleInputChange = (e) => {
        let { id, value } = e.target
        setState({ ...state, [id]: value })
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const [ip,setIp] = useState('');
    const getData = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/')
      setIp(res.data.IPv4);
    }
    
    useEffect(()=>{
        getData()
    },[])
    const handleClickLogin = async () => {
        await setOpen(true)
        if(username === "" || password === "") {
            setError(true)
            setErrorText("Input fields is required.")
            await setOpen(false)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please fill out the required fields!',
            })
        }
        else {
          const body = {"password": password}

          const headers = {
            headers: {
              'authorization': `${process.env.REACT_APP_AUTH}`
            }
          }
          axios.post(`${process.env.REACT_APP_API_URL}/api/inplay/encrypt?key=${process.env.REACT_APP_API_KEY}`, body , headers)
          .then((response) => {
            const newpw = response.data.results[0].inplayResult;
            const bodies = {
              "username": username,
              "password": newpw,
              "ip": ip
            
            }

            if(response.data.results.length > 0) {
              axios.post(`${process.env.REACT_APP_API_URL}/api/inplay/login?key=${process.env.REACT_APP_API_KEY}`, bodies , headers)
          .then((response) => {
            if(response.data.results[0].inplayResult > 0){
              sessionStorage.setItem('user', username)
              window.location.href = "/raffle"
            }else if(response.data.results[0].inplayResult === ''){
              alert(1)
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Username or Password!',
              })
            }
          })
            }
            else {

            }
          })
          .catch((err) => {
            Swal.fire(
              `${err.response.data.responseCode}`,
              `${err.response.data.responseCode} - ${err.response.data.responseStatus}`,
              'error'
            )
          })

          }

        await setOpen(false)
    }


    if(sessionStorage.getItem('user') === null){

      
    

    return ( 
        <>
    
        <Container className='my-5 w-25'>
          <div className='d-flex justify-content-center'><img src={Logo} alt="" className='logIn-logo'/></div>
          <h1 className='mb-5 '><span className='text-uppercase'>Chinese New Year</span><h2> Raffle Promo 2023</h2></h1>
        <FloatingLabel
          label="Username"
          className="mb-3 text-dark"
        >
          <Form.Control 
            type="text" 
            id="username" 
            onChange={handleInputChange} 
            value={username} 
            label="Username"
            
          />
        </FloatingLabel>
        <FloatingLabel 
          label="Password"
          className='text-dark'
          
        >
          <Form.Control 
            type="password" 
            id="password" 
            onChange={handleInputChange} 
            value={password} 
            label="Password" 
            show={showPassword} 
            clickShow={handleClickShowPassword}
          />
        </FloatingLabel>
      <Button variant="light text-danger text-uppercase w-100 mt-3 btn-logIn" onClick={handleClickLogin}>login</Button>{' '}
      <h6 className='mt-4 text-light text-center login-mail'>For Customer Service, please email us at helpdesk@instawin.ph</h6>
        </Container>

        </>
        
     );
    }else {
      setTimeout(() => {
          window.location.href = "/raffle"
      }, 2000)
      return(
          <>
              Checking Authentication...
          </>
      )
  }
}
 
export default Login;