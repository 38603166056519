import React, { useEffect, useState } from "react";
import axios from "axios";
import AnimatedNumbers from "react-animated-numbers";
import { Card, Row, Col, Button, Container, Modal } from "react-bootstrap";
import Navbar from './components/navbar';
import Confetti from "./images/congrats.gif";
import Draw from "./images/button.png";
import Swal from 'sweetalert2'

function Raffle() {
    const [num, setNum] = useState(0);
    const [num2, setNum2] = useState(0);
    const [num3, setNum3] = useState(0);
    const [num4, setNum4] = useState(0);
    const [num5, setNum5] = useState(0);
    const [num6, setNum6] = useState(0);
    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [overall, setOverall] = useState(0);
    const [entry, setEntry] = useState('');
    const [outlet, setOutlet] = useState('');
    const [card, setCard] = useState('');
    const [countWinner, setCountWinner] = useState(0);
    const [data, setData] = useState([]);
    const [draw, setDraw] = useState('Draw');
    const [disable, setDisable] = useState(false);
    const [arrow, setArrow] = useState(false);
    const [trophy, setTrophy] = useState(true);
    
    
    const handleClose = () => {
        setShow(false);
        setDraw('Draw')
        setNum('0')
        setNum2('0')
        setNum3('0')
        setNum4('0')
        setNum5('0')
        setNum6('0')
        getAllWinners()
        setArrow(false)
        setTrophy(true)
    };
 
    let arr = [num, num2, num3, num4, num5,num6]
    let d = new Date()

    let arrPrize = [
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 1,000.00",
        "Play Credits worth Php 3,000.00",
        "Play Credits worth Php 3,000.00",
        "Play Credits worth Php 3,000.00",
        "Play Credits worth Php 5,000.00",
        "Play Credits worth Php 5,000.00",
        "Play Credits worth Php 10,000.00",
        "GRAND PRIZE HONDA WAVE 110R",
    ]

    let date = new Date()

    const handleDrawBtn = async () => {
        setDisable(true)
        setTrophy(false)
        setDraw('Loading...')
        setArrow(true)
        if(countWinner === 13) {
            Swal.fire(
                'Raffle Completed!',
                'Congratulations to all winners.',
                'success'
            )
            setDraw('Draw')
            setArrow(false)
            setDisable(false)
            setTrophy(true)
        }
        else {

            let t = d.toISOString().split("T")[1]
            let tt = t.split(".")

            let datePeriod = ''
            
            const bodyPicker = {
                "raffleCode": "RF-LUTC2023",
                "drawPeriod": "MINOR",
                "drawDate": `2023-04-30`,
                "cashierLogin": "IWUL3T"
            }

            const config = {
                headers: {
                    'Authorization': `${process.env.REACT_APP_AUTH}`
                }
            }

            await axios
            .post(`${process.env.REACT_APP_URL}/api/inplay/raffle/picker?key=${process.env.REACT_APP_API_KEY}`, bodyPicker, config)
            .then(results => {
                var string = results.data.results[0].EntryNo
                var digits = string.toString().split('');
                var realDigits = digits.map(Number)
                
                    
                setNum(realDigits[0])
                setNum2(realDigits[1])
                setNum3(realDigits[2])
                setNum4(realDigits[3])
                setNum5(realDigits[4])
                setNum6(realDigits[5])
                if(results.data.results.length > 0) {
                    const body = {
                        "raffleCode": "RF-LUTC2023",
                        "rafflePrize": arrPrize[countWinner],
                        "cashierLogin": "IWUL3T",
                        "drawDate": `2023-04-30 ${date.toLocaleTimeString('en-US', {hour12: false})}`
                    }
                    axios.patch(`${process.env.REACT_APP_URL}/api/inplay/raffle/picker/${results.data.results[0].EntryNo}?key=${process.env.REACT_APP_API_KEY}`, body, config).then((res) => {
                        if(res.data.results === 1) {
                            setEntry(results.data.results[0].EntryNo)
                            setOutlet(results.data.results[0].OutletName)
                            setCard(results.data.results[0].CardNo)

                            let NameOfPlayer = results.data.results[0].FullName
                            var first3 = NameOfPlayer.toString().substring(0, 3);
                            var last4 = NameOfPlayer.toString().substring(NameOfPlayer.length - 4);
                            let mask = NameOfPlayer.toString().substring(3, NameOfPlayer.length - 4).replace(/./g,"*");

                            setName(`${first3}${mask}${last4}`)
                            setTimeout(() => {
                                setShow(true)
                                setDisable(false)
                            }, 7000)
                        }
                        else {
                            
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }

            })
        }
        
        
        
    }
    const getAllWinners = () => {
        const conf = {
            headers: {
                'authorization': `${process.env.REACT_APP_AUTH}`
            }
        }
        const bodies = {
            "raffleCode": "RF-LUTC2023",
            "drawPeriod": "MINOR",
            "drawDate": "2023-04-30",
            "cashierLogin": "IWUL3T"
        }
        axios
        .post(`${process.env.REACT_APP_URL}/api/inplay/raffle/winners?key=${process.env.REACT_APP_API_KEY}`, bodies, conf)
        .then(results => {
        setCountWinner(results.data.results.length)
        setData(results.data.results)
        }).catch((error) => {
            console.log(error)
        })
    }


    useEffect(() => {
        const confi = {
            headers: {
                'authorization': `${process.env.REACT_APP_AUTH}`
            }
        }
        const bodies = {
            "raffleCode": "RF-LUTC2023",
            "cardNo": "0680-7711-6463-7533",
            "dateFrom": "02/15/2023 06:00:00",
            "dateTo": "02/16/2023 05:59:59"
        }
        axios
        .post(`${process.env.REACT_APP_URL}/api/inplay/raffle/allEntries?key=${process.env.REACT_APP_API_KEY}`, bodies ,confi)
        .then(results => {
           const overall_entry = results.data.results[0].OverallEntries;
           setOverall(overall_entry)

        })
        getAllWinners()
    }, [])

  if(sessionStorage.getItem('user') === null) {
    window.location.href = '/login'
  }
  else {

    return (
        <>
            <div className="body-main">
            <Navbar data={data} count={countWinner} trophy={trophy}/>
            <Container className="pt-5 mt-5">
                <h1>Overall Total Entries: {overall}</h1>
                <Row>
                    {
                        arr.map((data, index) => {
                            return(
                                <Col className="p-0" key={index}>
                                    <Card className="border-0" style={{ background: 'transparent' }}>
                                        <h1 className="text-white" style={{ fontSize: 150 }}>
                                            <div className="raffle-num mt-5">
                                            <div className="d-flex justify-content-around pt-3">
                                                <AnimatedNumbers
                                                    animateToNumber={data}
                                                    configs={[
                                                        { mass: 1, tension: 220, friction: 100 },
                                                        { mass: 1, tension: 180, friction: 130 },
                                                        { mass: 1, tension: 280, friction: 90 },
                                                        { mass: 1, tension: 180, friction: 135 },
                                                        { mass: 1, tension: 260, friction: 100 },
                                                        { mass: 1, tension: 210, friction: 180 },
                                                        { mass: 1, tension: 210, friction: 180 },
                                                    ]}
                                                />
                                            </div>
                                            </div>
                                        </h1>
                                    </Card>
                                </Col>
                            )
                        })
                    }
    
                </Row>
                <Row className="d-flex justify-content-center my-5">
                {/* <Button variant="light" onClick={handleDrawBtn} className="w-25 text-danger draw text-uppercase" disabled={disable}>
                    
                    {draw}
                    { !arrow ? <CaretRightSquare size={30} style={{ cursor: 'pointer' , marginLeft: '1rem' }}/> : null }
                    
                    </Button> */}
                    {
                        disable? (
                            <>
                            <div className="d-flex justify-content-center">
                                <h5 className="text-center text-light">Please wait after the draw &nbsp; </h5><div class="loader"></div>
                            </div>
                            </>
                        ):(
                            <img src={Draw} alt="" onClick={handleDrawBtn} disabled={disable} className="w-25" style={{ cursor: 'pointer' , marginLeft: '1rem' }}/>
                            
                        )
                    }
                    
    
                <Modal show={show} className="mode">
                    <Modal.Header>
                        <Modal.Title><img src={Confetti} alt=""className="w-50 draw-img"/></Modal.Title>
                        
                    </Modal.Header>
                        <Modal.Body className="text-dark d-flex flex-column">
                            <h6>Entry No.:</h6>
                            <h2>{entry}</h2>
                            <h6>Outlet:</h6>
                            <h2>{outlet}</h2>
                            <h6>Fullname:</h6>
                            <h2>{name}</h2>
                            <h6>Username/Card No.:</h6>
                            <h2>{card}</h2>
                            <h6>Prize:</h6>
                            <h2>{arrPrize[countWinner]}</h2>
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
    
                        </Button>
                    </Modal.Footer>
                </Modal>
                </Row>
                
            </Container>
            </div>
            
            
        </>
      );
  }
}

export default Raffle;