import React, { useEffect, useState } from "react";
import Logo from "../images/new_logo.png";
import { Container, Row, Col, Table, Modal, Button, Badge, Card,Nav, Navbar   } from "react-bootstrap";
import { TrophyFill, Power, ArrowBarLeft, ArrowBarRight } from 'react-bootstrap-icons';
import Pagination from 'rc-pagination';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";

import axios from "axios";

const Navi = (props) => {
  const [reveal, setReveal] = useState(false);
  const [data, setData] = useState(false);
  const [cardNumber, setCardNumber] = useState('')

  // Pagination
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);


  const tableClose = () => setReveal(false);
  const tableShow = () => setReveal(true);
  const dataClose = () => setData(false);
  const dataShow = () => setData(true);
  const [chosen, setChosen] = useState([]);
  const [fresh, setFresh] = useState(false);
  const [total, setTotal] = useState('');
  const [loading, setLoading] = useState('Loading...');
  const freshShow = () => setFresh(true);
  const freshClose = () => setFresh(false);
  const [user, setUser] = useState('');
  const Closed = () => {
    freshClose(false);
    setData(false);
    setChosen([])
  }
  const [order, setOrder] = useState ("ASC")
  document.addEventListener("keydown", ((e) => {
    if(e.key === "F9") {
      dataShow()
      
    } 


}))

let d = new Date()

const Headers = ["Entry No.", "Raffle Program", "References No."]
let arr = []

const handleCardNumber = (e) => {
  setCardNumber(e.target.value)
}

const handleSearch = () => {
    setChosen([])
    const conf = {
      headers: {
          'authorization': `${process.env.REACT_APP_AUTH}`
      }
    }
    const bodies = {
      "raffleCode": "RF-LUTC2023",
      "cardNo": cardNumber
    }
  axios.post(`${process.env.REACT_APP_URL}/api/inplay/raffle/searchByCardNo?key=${process.env.REACT_APP_API_KEY}`,bodies , conf)
  .then((res) =>
    setChosen(res.data.results)
    
  )
  setTotal(chosen.length)
  freshShow()
  setLoading('Loading...')
}
const PerPageChange = (value) => {
  setSize(value);
  const newPerPage = Math.ceil(chosen.length / value);
  if (current > newPerPage) {
      setCurrent(newPerPage);
  }
}
const getData = (current, pageSize) => {
  // Normally you should get the data from the server
  return chosen.slice((current - 1) * pageSize, current * pageSize);
};

const PaginationChange = (page, pageSize) => {
  setCurrent(page);
  setSize(pageSize)
}

const PrevNextArrow = (current, type, originalElement) => {
  if (type === 'prev') {
      return <ArrowBarLeft/>;
  }
  if (type === 'next') {
      return <ArrowBarRight/>;
  }
  return originalElement;
}

useEffect(() => {
  setUser(sessionStorage.user)
}, [])

const Header = ["Entry No","Raffle Program", "Reference No"];

const logout = () => {
  Swal.fire({
    title: 'Are you sure you want to logout?',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#3ec400',
    cancelButtonColor: 'red'
  }).then((result) => {
    if (result.isConfirmed) {
      sessionStorage.removeItem('user')
      window.location.href = "/login"
    } else if (result.isDenied) {
      Swal.fire('Changes are not saved', '', 'info')
    }
  })
};
    return (
        <>
          <Navbar expand="lg" className="Phone mt-3">
            <Container>
              <Navbar.Brand href="#home"><img src={Logo} alt=""className="logo "/><span className="text-uppercase">Chinese New Year Raffle Promo 2023</span></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav me-auto">
                <Nav className="ms-auto display">
                <Nav.Link href="#link" className="hide"><span>Welcome,&nbsp; {user}</span></Nav.Link>
                  {props.trophy ? (<Nav.Link href="#home"><div className="badges"><TrophyFill size={30} onClick={tableShow} style={{ cursor: 'pointer' }} className="hide"/><Badge bg="primary rounded-circle hide"onClick={tableShow}>{props.count}</Badge></div><p onClick={tableShow}>All Winners</p> </Nav.Link>):(<Nav.Link href="#home"><div className="badges"><TrophyFill size={30} style={{ cursor: 'pointer' }} className="hide"/><Badge bg="primary rounded-circle hide">{props.count}</Badge></div><p>All Winners</p> </Nav.Link>)}
                  <Nav.Link href="#link"><Power size={30} onClickCapture={logout} style={{ cursor: 'pointer' }} className="hide"/><p onClickCapture={logout}>Logout</p></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
      <Modal

        show={reveal}
        onHide={tableClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">All Winners</Modal.Title>
        </Modal.Header>
        <Modal.Body className="table-trophy">
        <Table striped="columns w-100">
          <thead>
              <tr>
              <th>EntryNo</th>
              <th>Fullname</th>
              <th>Card No.</th>
              <th>Outlet</th>
              <th>Prize</th>
              <th>Ref No.</th>
              <th>Draw Date</th>
              </tr>
          </thead>
          <tbody>
              {
                props.data.map((d, index) => {
                  let ref = d.ExtRefId.split("-")
                  let dd = d.DrawDate.split("T")
                  let tt = dd[1].split(".")
                  return(
                    <>
                      <tr key={index}>
                        <td>{d.EntryNo}</td>
                        <td>{d.FullName}</td>
                        <td>{d.CardNo}</td>
                        <td>{d.OutletName}</td>
                        <td>{d.DrawPrize}</td>
                        <td>{ref[0]}</td>
                        <td>{dd[0]} {tt[0]}</td>
                      </tr>
                    </>
                  )
                })
              }
          </tbody>
        </Table>
        </Modal.Body>
      </Modal>


      <Container>
      <Modal
        show={data}
        onHide={dataClose}
        backdrop="static"
        keyboard={false}
        className="bg-trans"
      >
        <Card>
      <Card.Body>
        <Card.Title className="text-dark text-uppercase">Generate Player Entries</Card.Title>
        <div className="d-flex justify-content-between">
        <input type="text" id="player_value" onChange={handleCardNumber} placeholder="Enter Card Number" className="p-1 w-75"/>
        <button className="btn btn-danger ms-5" id="search" type="button" onClick={handleSearch}>Search</button>
        </div>
      </Card.Body>
    </Card>
    <Card className="mt-5">
      <Container className="d-flex justify-content-end mt-3">
      <CSVLink filename="Player_Entries.csv" data= {chosen} className="text-light text-decoration-none btn btn-danger w-25" disabled={fresh ? false : true}>Save as CSV</CSVLink>
      </Container>
      <Card.Body className="" >
      {fresh ? (
        <>
          <div className="container-fluid">
          <div className="card">
                        <div className="card-body p-0">
                            
                            <div className="table-responsive">
                                <table className="table table-text-small mb-0">
                                    <thead className="thead-primary table-sorting">
                                        <tr className="text-uppercase">
                                            <th>Entry No</th>
                                            <th>Raffle Program</th>
                                            <th>Reference No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          chosen.length === 0 ?
                                          <>
                                            <tr>
                                              <td className="text-dark text-center " colSpan={3}>No data to show</td>
                                            </tr>
                                          </> :
                                          chosen.slice((current - 1) * size, current * size).map((data, index) => {
                                            return(
                                              <>
                                                <tr key={index}>
                                                  <td>{data.EntryNo}</td>
                                                  <td>{data.GPSource}</td>
                                                  <td>{data.EntryId}</td>
                                                </tr>
                                              </>
                                            )
                                          })
                                        }
                                    </tbody>
                                </table>
                            </div>
                          <div className="table-filter-info">
                                
                                <Pagination
                                    className="pagination-data"
                                    showTotal={(total, range) => total === 0 ? `Showing 0 of 0` : `Showing ${range[0]}-${range[1]} of ${total}`}
                                    onChange={PaginationChange}
                                    total={chosen.length === 0 ? 0 : chosen.length}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </>

        ) : (
          <>
        <div className="table-responsive">
                                <table className="table table-text-small mb-0">
                                    <thead className="thead-primary table-sorting">
                                        <tr className="text-uppercase">
                                            <th>Entry No.</th>
                                            <th>Raffle Program</th>
                                            <th>Reference No</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {
                                          fresh ?(
                                            
                                            <div class="loader"></div>
                                            
                                          ):(
                                            <>
                                            <tr>
                                            <td className="text-dark text-center " colSpan={3}>No data to show</td>
                                            </tr>
                                            </>
                                          )
                                        }
                                    </tbody>
                                </table>
                            </div>
          </>
       )}
      </Card.Body>

      <Container className="d-flex justify-content-end">
        <button className="btn text-danger w-25" onClick={Closed}>CLOSE</button>
      </Container>
    </Card >
      </Modal >
      </Container>

      </>
     );
}

export default Navi;