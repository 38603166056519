import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import './index.css';

import Login from './login.js'
import Raffle from './raffle.js'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/raffle" component={Raffle} />
      </Switch>
    </Router>
  );
}

export default App;
